import { useAuth0 } from "@auth0/auth0-react";

import React, { useContext, useEffect, useState } from "react";

interface Auth0AccessTokenContextInitial {
  status: "initial";
}

interface Auth0AccessTokenContextPending {
  status: "pending";
}

interface Auth0AccessTokenContextAuthenticated {
  status: "authenticated";
  token: string;
}

interface Auth0AccessTokenContextUnauthenticated {
  status: "unauthenticated";
}

type Auth0AccessTokenContextInterface =
  | Auth0AccessTokenContextInitial
  | Auth0AccessTokenContextPending
  | Auth0AccessTokenContextAuthenticated
  | Auth0AccessTokenContextUnauthenticated;

export const Auth0AccessTokenContext = React.createContext<Auth0AccessTokenContextInterface>({ status: "initial" });

export default function Auth0AccessTokenProvider(props: React.PropsWithChildren<unknown>) {
  const { children } = props;

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [status, setStatus] = useState("initial");
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const execute = async () => {
      try {
        const token = await getAccessTokenSilently({ audience: process.env.AUTH0_AUDIENCE });
        setToken(token);
        setStatus("authenticated");
      } catch (error) {
        setStatus("unauthenticated");
        console.warn("Auth0AccessTokenProvider", error);
      }
    };

    if (isLoading) {
      setStatus("pending");
    } else if (isAuthenticated) {
      execute();
    } else {
      setStatus("unauthenticated");
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return (
    <Auth0AccessTokenContext.Provider
      value={{
        status: status as "initial" | "pending" | "authenticated" | "unauthenticated",
        token: token as string,
      }}
    >
      {children}
    </Auth0AccessTokenContext.Provider>
  );
}

export function useAuth0AccessTokenContext(): Auth0AccessTokenContextInterface {
  return useContext(Auth0AccessTokenContext);
}
