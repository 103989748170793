import React, { useMemo } from "react";
import useSWR from "swr";

export type Status = "initial" | "pending" | "fulfilled" | "rejected";

export type ChildrenFunction<Data = any, Error = any> = (args: {
  data?: Data;
  error?: Error;
  mutate: any;
  status: Status;
}) => React.ReactElement<any, any>;

export const Fetcher = (options: object) => (url: string) => fetch(url, options).then((res) => res.json());

const useSWRWrapper = <Data, Error>(...args: any[]) => {
  const response = useSWR<Data, Error>(...(args as any));
  const status = useMemo(() => {
    if (response.data) {
      return "fulfilled";
    }

    if (response.error) {
      return "rejected";
    }

    if (response.isValidating !== false) {
      return "pending";
    }

    return "initial";
  }, [response.data, response.error, response.isValidating]) as Status;

  return { ...response, status };
};

export default useSWRWrapper;
