import "./src/styles/index.scss";

import { Auth0Provider } from "@auth0/auth0-react";

import React from "react";

import Auth0AccessTokenProvider from "./src/components/common/Auth0AccessToken";
import { ProfileProvider } from "./src/components/remote/Profile";

export const wrapRootElement = ({ element }) => {
  const options = {
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
    clientId: process.env.AUTH0_CLIENT_ID,
    redirectUri: process.env.AUTH0_REDIRECT_URI,
  };

  return (
    <Auth0Provider {...options} cacheLocation="localstorage" useRefreshTokens={true} maxAge={24 * 60 * 60 * 1000}>
      <Auth0AccessTokenProvider>
        <ProfileProvider>{element}</ProfileProvider>
      </Auth0AccessTokenProvider>
    </Auth0Provider>
  );
};
