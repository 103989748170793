import * as _ from "lodash";
import React, { useContext, useMemo } from "react";
import { mutate } from "swr";

import useSWRWrapper, { Fetcher, ChildrenFunction } from "../../hooks/useSWRWrapper";
import { Profile as ProfileInterface } from "../../interfaces";
import { useAuth0AccessTokenContext } from "../common/Auth0AccessToken";

export const updateProfile = async (data: ProfileInterface, token: string) => {
  mutate("/api/profile", data, false);

  await fetch("/api/profile", {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(_.omit(data, "id")),
  });

  mutate("/api/profile");
};

export const useProfile = () => {
  const auth0 = useAuth0AccessTokenContext();
  const headers = useMemo(() => {
    const head: any = {
      accept: "application/json",
      "Content-Type": "application/json",
    };

    if (auth0.status === "authenticated") {
      head.Authorization = `Bearer ${auth0.token}`;
    }

    return head;
  }, [auth0]);
  const response = useSWRWrapper<ProfileInterface, Error>(
    () => (auth0.status === "authenticated" ? "/api/profile" : null),
    Fetcher({
      method: "GET",
      headers,
    })
  );
  const status = useMemo(() => {
    if (auth0.status === "authenticated") {
      switch (response.status) {
        case "fulfilled":
          return "authenticated";

        case "rejected":
          return "unauthenticated";

        default:
          return response.status;
      }
    }

    return auth0.status;
  }, [auth0.status, response.status]);
  return {
    ...response,
    status,
  };
};

const ProfileRemote: React.FC<unknown> = (props) => {
  const { children } = props;
  const response = useProfile();

  return (children as ChildrenFunction<ProfileInterface, Error>)(response as any);
};

export interface ProfileContextInitialInterface {
  status: "initial";
}

export interface ProfileContextPendingInterface {
  status: "pending";
}

export interface ProfileContextAuthenticatedInterface {
  status: "authenticated";
  data: ProfileInterface;
  setData: (profile: ProfileInterface) => void;
  reload: () => void;
  mutate: any;
}

export interface ProfileContextUnauthenticatedInterface {
  status: "unauthenticated";
}

export type ProfileContextInterface =
  | ProfileContextInitialInterface
  | ProfileContextPendingInterface
  | ProfileContextAuthenticatedInterface
  | ProfileContextUnauthenticatedInterface;

export const ProfileContext = React.createContext<ProfileContextInterface>({ status: "initial" });

export function ProfileProvider(props: React.PropsWithChildren<unknown>) {
  const { children } = props;

  return (
    <ProfileRemote>
      {({ data, mutate, status }) => {
        if (data) {
          return (
            <ProfileContext.Provider
              value={{
                status,
                data: data,
                setData: mutate,
                reload: mutate,
                mutate,
              }}
            >
              {children}
            </ProfileContext.Provider>
          );
        } else {
          return (
            <ProfileContext.Provider
              value={{
                status,
              }}
            >
              {children}
            </ProfileContext.Provider>
          );
        }
      }}
    </ProfileRemote>
  );
}

export function useProfileContext() {
  return useContext(ProfileContext);
}

export default ProfileRemote;
